import React, { Component } from 'react';
import fire from './fire';

class Storage extends Component {
    constructor(props) {
        super(props);
        this.state = { messages: [] }; // <- set up react state
    }
    componentWillMount(){

        /* Create reference to messages in Firebase Database */
        /* Retrieve the first 100 */
        let messagesRef = fire.database().ref('messages').orderByKey().limitToLast(100);
        debugger;

        /* This is done whenever something is added to this database */
        /* This is listening for Database changes */
        /* EventHandler */
        messagesRef.on('child_added', snapshot => {

            /* Update React state when message is added at Firebase Database */
            /* pulls it from the database to put in the state to display */

            let message = { text: snapshot.val(), id: snapshot.key };
            this.setState({ messages: [message].concat(this.state.messages) });
        })
    }

    addMessage(e){
        e.preventDefault(); // <- prevent form submit from reloading the page
        /* Send the message to Firebase */
        fire.database().ref('messages').push( this.inputEl.value );
        this.inputEl.value = ''; // <- clear the input
    }

    render() {
        return (
            <form onSubmit={this.addMessage.bind(this)}>
                <input type="text" ref={ el => this.inputEl = el }/>
                <input type="submit"/>
                <ul>
                    { /* Render the list of messages */
                        this.state.messages.map( message => <li key={message.id}>{message.text}</li> )
                    }
                </ul>
            </form>
        );
    }
}

export default Storage;
