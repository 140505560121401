import firebase from 'firebase'
var config = {
    apiKey: "AIzaSyD3qZJaJHl-YR5bl5NVm_eXYuTW-9dvSRk",
    authDomain: "reacttabs.firebaseapp.com",
    databaseURL: "https://reacttabs.firebaseio.com",
    projectId: "reacttabs",
    storageBucket: "reacttabs.appspot.com",
    messagingSenderId: "390134234538",
    appId: "1:390134234538:web:a8b73cc91336295fa4ffd3"
};
var fire = firebase.initializeApp(config);
export default fire;
