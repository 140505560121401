import React, {Component} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Pacman from 'pacman-react/dist/';
import "react-tabs/style/react-tabs.css";
import './Tabs.css';
import Emoji from 'a11y-react-emoji';
import 'cors-anywhere/lib/cors-anywhere';
import 'jquery-ui';



// Footer Function
class HeartFooter extends Component {
    render() {
        return <footer>
            Made with:
            {' '}
            <Emoji symbol={"💜"} label={"love"}/>
            {' '}
            and
            {' '}
            <Emoji symbol={"💅🏾"} label={"style"}/>
            {' '}
            by Patricia Parker
        </footer>
    }
}


// Tabs Initialization
class Tabs1 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gridSize: 12,
            links: [],
            gifs : [],
            message : "This is my original string."
        };
    }



    // Done before the page loads.  Calculations, API calls, etc. This is where the state is reset.
    componentDidMount() {

        //fetch('https://cors-anywhere.herokuapp.com/https://shibe.online/api/shibes?count=10&urls=true&httpsUrls=true')
        //  fetch('https://media1.giphy.com/media/1080KoYO1tkYuY/100.gif?cid=6618e5356da271d39ca2e757007f0fadfcb1c69325df43fd&rid=100.gif')
          //fetch('https://api.giphy.com/v1/gifs/search?q=ryan+gosling&api_key=490THG0Z1RxZFY90wl7AzRfz3nZi9Lux&limit=10/data[%22bitly_gif_urll%22]')
          fetch('https://api.giphy.com/v1/gifs/search?q=ryan+gosling&api_key=490THG0Z1RxZFY90wl7AzRfz3nZi9Lux&limit=10/data["images"]["fixed_height"]["url"]')
            .then(response => response.json())
            .then(response => {

              //  alert('value is: ' + response.data[0].bitly_url)
                    // do not change the state this way...  this.state.links = response;
                    //this.setState({links: response})
                    this.setState({gifs : response.data})

                }
            );
    }


    // This function is copying the links array and changing the second element in that array when run.
    changeMyArray() {
        let copyOfStateArray = this.state.gifs;
        copyOfStateArray[1].bitly_url = "https://www.google.com";
        alert("List Item #2 has changed to: "+ copyOfStateArray[1].bitly_url);
        this.setState({gifs: copyOfStateArray})
    }


    // This function is changing the greeting displayed at bottom of page when run.
    changeMyGreeting() {
        this.setState({message: "New Greeting!"})
    }


    // This is the starting of React application.
    render() {
        return <div className={"container"}>
            <Tabs className={"container"}>

                {/* This is the tab list at top of page. */}
                <TabList className={"container"}>
                    <Tab>About Me</Tab>
                    <Tab>Let's Play</Tab>
                    <Tab>Reading An API</Tab>
                </TabList>

                {/* This is the first tab. */}
                <TabPanel>
                    <div className={"container-fluid"} >

                    <h1>About Me</h1>
                        <div className={"jumbotron jumbotron-fluid"}>
                            <p>
                                <img className={"mx-auto d-block img-fluid"} alt={"Patricia"}
                                     src={"https://s3-us-west-2.amazonaws.com/s.cdpn.io/197130/patCircle.png"}/>
                            </p>
                        </div>
                    <p>
                        <h1 className={"text-center"}>
                            <em>Creative Technologist that identifies opportunities and finds solutions.<br/>
                                I want good karma. I love life and myself.</em><br/>
                            <p>
                            {' '}
                            <Emoji symbol={"🦄"} label={"unicorn"}/>
                            {' '}
                            {' '}
                            <Emoji symbol={"👩🏾‍💻"} label={"girlWithComputer"}/>
                            {' '}
                            {' '}
                            <Emoji symbol={"🙋🏾"} label={"girlHandRaised"}/>
                            {' '}
                            </p>
                        </h1>
                    </p>
                    </div>
                </TabPanel>

                {/* This is the second tab. */}
                <TabPanel>
                    <div className={"container-fluid"}>
                    <h1>Let's Play</h1>
                    <p>
                        {<Pacman/>}
                    </p>
                    </div>
                </TabPanel>

                {/* This is the third tab. */}
                <TabPanel>
                    <div className={"container-fluid"}>
                        {/* this is how to connect a function to an onClick event in react */}
                        <h1>Reading An API</h1>
                            <p>
                                <button onClick={() => this.changeMyArray()}>Updated!</button>
                            </p>

                            <div className={"list-group"}>
                                {
                                    this.state.gifs.map((response, index) =>
                                        <div className={"row"}>
                                            <div className={"col-md-12"}>
                                                <li className={"list-group-item list-group-item-action"} key={index}>
                                                    <a href={response.bitly_url}>This is list item #{index + 1}.</a>
                                                </li>
                                            </div>
                                        </div>)
                                }
                            </div>
                            <p>
                                {this.state.message}
                            </p>
                            <p>
                                {/* this is how to connect a function to an onClick event in react */}
                                <button onClick={() => this.changeMyGreeting()}>Change Me!</button>
                            </p>
                        </div>
                </TabPanel>
            </Tabs>

            {/* This displays the footer */}
            <HeartFooter/>
        </div>


    }

}


export default Tabs1;
